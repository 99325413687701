import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import ModalForm from "../Modals/ModalForm";
import { operations } from "state/ducks/supersupplier";

const SupplierDataTable = (props) => {
  const { admin, items, buttonLabel, departments } = props;
  const dispatch = useDispatch();


  const handleDepartmentChange = (adminId, departmentCode, supplierId) => {
    dispatch(
          operations.reatributeDepartmentAsSuper(adminId, departmentCode, supplierId)
        ).then((response) => {
          window.location.reload();
        });
  };
  const departmentRender = departments.map((item) => {
    return (
      <tr key={item.departmentCode}>
        <th scope="row">
          {item.departmentCode}
        </th>
        <td>
          <select
            className="form-control"
            onChange={(e) => handleDepartmentChange(admin.id, item.departmentCode, e.target.value)}
            defaultValue={item.id}
           
          >
            <option value={item.id} disabled>{item.email}</option>
            <option value={admin.id}>{admin.email}</option>
            {items.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.email}
                </option>
              );
            })}
          </select>

        </td>
        <td>{item.code}</td>
        <td>
          <ModalForm item={item} admin={admin} buttonLabel={buttonLabel} />
        </td>
        <td>
          <Link
            to={{
              pathname: `/compte/fournisseur/comptes/${item.id}/commandes`,
            }}
          >
            <Button
              className="btn-green float-right"
              style={{ fontSize: "75%" }}
            >
              Paniers en cours
            </Button>
          </Link>
        </td>
        <td>
        </td>
        {/* <td>
          {item.departmentCodes && item.departmentCodes.length > 0 && item.departmentCodes.map((departmentCode) => {
            return (
              <span key={departmentCode}>
                {departmentCode}, 
              </span>
            );
          })
          }
        </td> */}
        {/* <td>
          <Button
            color="danger"
            style={{ fontSize: "75%" }}
            onClick={() => deleteItem(item.id)}
          >
            Supprimer
          </Button>
        </td> */}
      </tr>
    );
  });

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Departement</th>
          <th>Interlocuteur</th>
          <th>Code</th>
          <th>Actions</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>{departmentRender}</tbody>
    </Table>
  );
};

export default SupplierDataTable;
