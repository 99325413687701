import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { centimesToCurrency } from "utils/Utils";
import { centimesToCurrency4decimaux } from "utils/Utils";
import "./CartBanner.css";

const CartBanner = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector((state) => state.session, shallowEqual);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const user = session.user;
  const activeCart = session.activeCart;
  const activeFreeCart = session.activeFreeCart;
  const pendingCarts = session.pendingCarts;
  const pendingFreeCarts = session.pendingFreeCarts;

  let cartType, currentCart, currentPendingCarts;

  const calculateTotalPrice = (cart) => {
    let totalPrice = 0;
    let hasSpecialArticle = false;

    if (cart && cart.orderLots && cart.orderLots.length > 0) {
      cart.orderLots.forEach((lot) => {
        if (lot.orderArticles && lot.orderArticles.length > 0) {
          lot.orderArticles.forEach((article) => {
            let articlePrice = article.quantity * article.price;
            if (lot.lotCode === 105 && article.unit === "€ (Budget)") {
              articlePrice /= 100;
              hasSpecialArticle = true;
            }
            totalPrice += articlePrice;
          });
        }
      });
    }

    const formattedTotalPrice = hasSpecialArticle
      ? centimesToCurrency4decimaux(totalPrice)
      : centimesToCurrency(totalPrice);

    return formattedTotalPrice;
  };

  if (location.pathname.includes("/catalogue/libre")) {
    cartType = "free";
    currentCart = activeFreeCart;
    currentPendingCarts = pendingFreeCarts;
  } else if (location.pathname === "/catalogue") {
    cartType = "formalized";
    currentCart = activeCart;
    currentPendingCarts = pendingCarts;
  }

  const handleSelect = (cartId) => {
    dispatch(
      sessionOperations.changeActiveCart(
        user.id,
        user.roles,
        cartId,
        cartType
      )
    );
    setIsDropdownOpen(false);
  };

  return cartType && user ? (
    <Row className="cartBanner">
      <Row
        style={{
          maxWidth: "1245px",
          width: "100%",
          margin: "0 auto",
          padding: "10px 0 10px 0",
          display: "flex", // Flexbox pour alignement
          alignItems: "stretch", // Alignement des colonnes en hauteur
        }}
      >
        <Col
          md={2}
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            className="btn-white btn btn-secondary"
            style={{ height: "70px", width: "100%" }}
            onClick={() =>
              dispatch(
                modalsOperations.getModalStatus(
                  user.roles.includes("ROLE_SUPPLIER") && cartType === "formalized"
                    ? "panier_create_as_supplier"
                    : user.roles.includes("ROLE_SUPPLIER") && cartType === "free"
                      ? "panier_libre_create_as_supplier"
                      : user.roles.includes("ROLE_CUSTOMER") && cartType === "formalized"
                        ? "panier_create"
                        : user.roles.includes("ROLE_CUSTOMER") && cartType === "free"
                          ? "panier_libre_create"
                          : "",
                  true,
                  null
                )
              )
            }
          >
            {/* Nouveau panier {cartType === "formalized" ? "formalisé" : "libre"} */}
            Nouveau panier
          </button>
        </Col>
        <Col
          md={6}
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                cursor: "pointer",
                background: "#fff",
                borderRadius: "4px",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "70px", // Hauteur maximale
              }}
            >
              <div
                style={{
                  textAlign: "left", // Aligne le texte à gauche
                  margin: 0, // Supprime les marges par défaut
                  padding: 0, // Supprime les paddings par défaut
                }}
              >
                {currentCart ? (
                  <>
                    <strong>Panier en cours :</strong> {currentCart.name}
                    <br />
                    <small>
                      Département {currentCart.postal}, {currentCart.countArticles} articles,{" "}
                      {calculateTotalPrice(currentCart)}
                    </small>
                  </>
                ) : (
                  <div>Merci de choisir un panier</div>
                )}
              </div>
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  borderTop: "6px solid #333",
                  marginLeft: "10px",
                }}
              />
            </div>
            {isDropdownOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  border: "1px solid #ccc",
                  background: "#fff",
                  zIndex: 10,
                  borderRadius: "4px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {currentPendingCarts && currentPendingCarts.length > 0 ? (
                  currentPendingCarts.map((cart, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelect(cart.id)}
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      {console.log(cart)}
                      <strong>{cart.name}</strong>
                      <br />
                      <small>
                        {cart.countArticles} articles,{" "}
                        {centimesToCurrency(cart.price)}
                      </small>
                    </div>
                  ))
                ) : (
                  <div style={{ padding: "10px" }}>Aucun panier disponible</div>
                )}
              </div>
            )}
          </div>
        </Col>

        {currentCart?.id ? (
          <Col md={2} style={{ margin: "auto" }}>
            <Link
              to={
                cartType === "free"
                  ? `/compte/panier/libre/${currentCart.id}`
                  : `/compte/panier/${currentCart.id}`
              }
            >
              <button
                className="btn-white btn btn-secondary"
                style={{ width: "100%", height: "70px" }}
              >

                Voir le panier en cours
              </button>
            </Link>

          </Col>
        ) : (
          <Col md={2} style={{ margin: "auto" }}></Col>
        )}

        <Col
          md={2}
          style={{
            margin: "auto",
          }}
        >
          <Link
            to={
              user.roles.includes("ROLE_SUPPLIER")
                ? "/compte/fournisseur/paniers"
                : "/compte/paniers"
            }
          >
            <button
              className="btn-white btn btn-secondary"
              style={{ width: "100%", height: "70px" }}
            >

              Tous mes paniers{" "}
              {cartType === "formalized" ? "formalisés" : "libres"}
            </button>
          </Link>
        </Col>
      </Row>
    </Row>

  ) : (
    <></>
  );
};

// export
export default CartBanner;
